import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import LandingPage from "../views/LandingPage";
import AboutUs from "../views/AboutUs";
import Services from "../views/Services";
import Blog from "../views/Blog";
import Article from "../views/Article";
import Gallery from "../views/Gallery";
import Location from "../views/Location";
import Contact from "../views/Contact";
import FAQ from "../views/FAQ";
import FaqArticle from "../views/FaqArticle";
import Error404 from "../views/Error404";
import ServiceArticle from "../views/ServiceArticle";
import Ocala from "../views/Location/Cities/Ocala";
import Lakeland from "../views/Location/Cities/Lakeland";
import Kissimmee from "../views/Location/Cities/Kissimmee";
import Jacksonville from "../views/Location/Cities/Jacksonville";
import Melbourne from "../views/Location/Cities/Melbourne";
import Daytona from "../views/Location/Cities/Daytona";
import Tampa from "../views/Location/Cities/Tampa";

export const AppRouter = () => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/:article" element={<ServiceArticle />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:article" element={<Article />} />
        <Route path="/locations" element={<Location />} />
        <Route path="/faqs" element={<FAQ />} />
        <Route path="/faqs/:article" element={<FaqArticle />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/ocala" element={<Ocala />} />
        <Route path="/lakeland" element={<Lakeland />} />
        <Route path="/kissimmee" element={<Kissimmee />} />
        <Route path="/jacksonville" element={<Jacksonville />} />
        <Route path="/melbourne" element={<Melbourne />} />
        <Route path="/daytona" element={<Daytona />} />
        <Route path="/tampa" element={<Tampa />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </SnackbarProvider>
  );
};
